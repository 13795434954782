import "core-js/modules/es.array.push.js";
import { checkPageKD, getQuestionBySubject } from "@/api/api";
export default {
  name: "index",
  data() {
    return {
      subjectList: [{
        name: '数学'
      }, {
        name: '语文'
      }, {
        name: '英语'
      }],
      questionList: [],
      baseImg: '',
      resultYJ: null,
      questionInfo: {
        "question_class": "",
        "question_source": "",
        "question_id": "",
        "question_type": 1,
        "judge_method": 0,
        "category": "",
        "total_score": 8,
        "stem": {
          "contents": ["已知x+4y=5求16xy的最大值是多少？"],
          "question": "",
          "geoInfo": {
            "variable_equals": {},
            "coordinates": {},
            "collineations": {},
            "circles": []
          },
          "facts": [],
          "notice": []
        },
        "subStems": [],
        "standardAnswers": [{
          "answerSteps": []
        }],
        "studentAnswers": [{
          "student_id": "",
          "answer_image_type": "jpg",
          "answer_image": "",
          "answerSteps": [{
            "stepNum": 1,
            "content": ""
          }]
        }]
      }
    };
  },
  mounted() {
    //this.getList();
  },
  methods: {
    checkPage() {
      checkPageKD(this.questionInfo).then(res => {
        this.resultYJ = res.data;
      });
    },
    chooseSub(e) {
      getQuestionBySubject({
        question_class: e
      }).then(res => {
        this.questionList = res.data;
        console.log(res);
      });
    },
    chooseQues(e) {
      if (this.questionInfo.question_class) {
        let s = this.questionList;
        s.find(res => {
          console.log(res.id, e);
          if (res.id == e) {
            this.questionInfo.question_source = res.no;
            this.questionInfo.total_score = res.total_score;
            this.questionInfo.question = res.question;
            this.questionInfo.stem.contents = res.stem.contents;
            this.questionInfo.standardAnswers = res.standardAnswers;
          }
        });
      }
    },
    addStep() {
      let l = this.questionInfo.studentAnswers[0].answerSteps.length;
      this.questionInfo.studentAnswers[0].answerSteps.push({
        stepNum: l + 1,
        content: ""
      });
    },
    removeStep(index) {
      this.questionInfo.studentAnswers[0].answerSteps.splice(index, 1);
      for (let s in this.questionInfo.studentAnswers[0].answerSteps) {
        this.questionInfo.studentAnswers[0].answerSteps[s].stepNum = parseInt(s) + 1;
      }
    },
    beforeAvatarUpload(e) {
      this.$root.getBase64(e).then(res => {
        this.questionInfo.studentAnswers[0].answer_image = res;
        this.baseImg = res;
      });
      //console.log(base64);
    }
  }
};